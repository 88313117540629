import Condition from "@mkt/ui/components/common/Condition"

import Details from "../Details"
import * as S from "./styles"

const Content = ({ content, children }) => {
  const { details, document_and_conditions: documentoAndConditions } = content

  return (
    <div className="article-content">
      <div css={S.articleContentContainer}>
        <div css={S.articleContentWrap}>
          <div css={S.articleContentCard}>
            <Condition condition={details.length > 0}>
              <Details blok={details} titleSection="Mais detalhes" />
            </Condition>
            <Condition condition={documentoAndConditions.length > 0}>
              <Details
                blok={documentoAndConditions}
                titleSection="Documentação e condições"
              />
            </Condition>
          </div>

          <div css={S.articleFormContainer} data-id-ui="form-flow">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Content
