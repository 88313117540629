import { css } from "@emotion/react"
import { above, below } from "@mkt/ui/styles/Breakpoints"
import { container } from "@mkt/ui/styles/Functions"

export const HeroContainer = css`
  ${container};
  display: flex;
  flex-direction: column;
  align-items: center;

  ${above["l"]} {
    flex-direction: row;
    padding-top: 48px;
    padding-bottom: 40px;
    padding-left: 56px;
    justify-content: flex-start;
  }

  ${below["l"]} {
    padding-top: 28px;
    padding-bottom: 28px;
  }
`

export const HeroImageContainer = css`
  width: 100%;
  max-width: 440px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${above["l"]} {
    width: 150px;
  }
`

export const HeroImage = css`
  max-width: 100%;
`

export const HeroTitle = css`
  width: 100%;
  text-align: center;

  ${above["l"]} {
    width: auto;
    margin: 0 35px;
  }

  ${below["l"]} {
    min-height: 112px;

    & > h4 {
      margin: 15px auto;
    }
  }
`

export const HeroAccountPercentage = css`
  margin-bottom: 6px;
  max-width: 250px;

  ${above["l"]} {
    font-size: 21px;
  }
`
