import Text from "@mkt/ui/components/Azulis/Text"
import Condition from "@mkt/ui/components/common/Condition"
import SbEditable from "@mkt/ui/components/common/SbEditable"
import { render } from "storyblok-rich-text-react-renderer"
import { CheckBlue, CloseGray } from "@mkt/ui/icons/Azulis"

import * as S from "./styles"

const Details = ({ blok, titleSection }) => {
  return (
    <div css={S.Details}>
      <Text as="h4" variant={["SemiBold", "HeadingFour"]}>
        {titleSection}:
      </Text>
      <ul css={S.DetailList}>
        <SbEditable content={blok}>
          {blok?.map((item, index) => {
            const { _uid, title, desc, icon } = item
            const isCloseGray = icon === "close-gray"
            const isActive = isCloseGray ? S.TextGray : S.TextBlack
            const IconRender = isCloseGray ? CloseGray : CheckBlue
            return (
              <SbEditable
                content={item}
                key={`#details-product-${_uid}-${index}`}
              >
                <li css={S.DetailItem}>
                  <Condition condition={desc}>
                    <Text as="p" variant={["Text14", S.DetailItemText]}>
                      {`${title}:`}
                      <Text as="span" variant={["Text14", "SemiBold"]}>
                        {render(desc)}
                      </Text>
                    </Text>
                  </Condition>

                  <Condition condition={!desc}>
                    <div css={S.DetailIcon}>
                      <IconRender />
                    </div>
                    <Text as="p" variant={["Text14", isActive]}>
                      {title}
                    </Text>
                  </Condition>
                </li>
              </SbEditable>
            )
          })}
        </SbEditable>
      </ul>
    </div>
  )
}

export default Details
