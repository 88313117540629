/* globals APP_NAME */

import { APP_NAME } from "gatsby-env-variables"

const Img = ({
  className,
  src,
  alt,
  width,
  height,
  loading = "lazy",
  draggable = "false",
  optimize = true,
}) => {
  const optimizedImg = optimize ? "/m/" : ""
  const mkt =
    APP_NAME === "ccbr"
      ? "cartoes"
      : APP_NAME === "mastercard" || APP_NAME === "cp"
      ? "iq"
      : APP_NAME
  const isStoryblokImg = /a.storyblok.com/.test(src)
  const isSvg =
    src?.includes("svg") || src?.includes("data:image")
      ? src
      : src + optimizedImg
  const replaceImg = isStoryblokImg
    ? src?.replace("a.storyblok.com", `assets.${mkt}.com.br`) + optimizedImg
    : isSvg

  return (
    <img
      className={className}
      src={replaceImg || src}
      alt={alt}
      width={width}
      height={height}
      loading={loading}
      draggable={draggable}
    />
  )
}

export default Img
