import Condition from "@mkt/ui/components/common/Condition"
import Text from "@mkt/ui/components/Azulis/Text"
import Img from "@mkt/ui/components/common/Img"

import * as S from "./styles"

const Hero = ({ content }) => {
  return (
    <Condition condition={!!content}>
      <div className="article-hero">
        <div css={S.HeroContainer}>
          <figure css={S.HeroImageContainer}>
            <Img
              src={content.image.filename}
              alt={`Imagem do logo da conta digital ${content.title_account}`}
              css={S.HeroImage}
            />
          </figure>
          <div css={S.HeroTitle}>
            <Text
              as="h4"
              variant={["HeadingFour", "TextLeft", S.HeroAccountPercentage]}
            >
              Conta Digital {content?.title_account}
            </Text>
          </div>
        </div>
      </div>
    </Condition>
  )
}

export default Hero
