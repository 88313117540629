import { jsx } from "@emotion/react"

import * as S from "./styles"

const Text = ({ variant = "Text", as = "p", children }) => {
  const classFormatting = (variants) =>
    variants.map((variant) => {
      if (!S[variant]) return variant

      return S[variant]
    })
  const getVariants =
    typeof variant === "string" ? S[variant] : classFormatting(variant)

  const CreateTagHTML = () => {
    const createdTag = jsx(as, { css: getVariants }, children)
    return createdTag
  }

  return <CreateTagHTML>{children}</CreateTagHTML>
}

export default Text
