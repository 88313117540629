import { css } from "@emotion/react"
import { colors } from "@mkt/azulis/src/styles/Tokens"
import { above } from "@mkt/ui/styles/Breakpoints"

export const Details = css`
  width: 100%;
  padding: 21px 0;
  ${above["l"]} {
    margin-right: 32px;
  }
`

export const DetailList = css`
  list-style: none;
  line-height: 30px;
  margin-top: 16px;
  max-width: 590px;
`

export const DetailItemText = css`
  display: flex;
  gap: 6px;
`

export const DetailItem = css`
  display: flex;
  align-items: center;
  gap: 6px;
`

export const TextGray = css`
  color: ${colors.gray.medium};
`

export const TextBlack = css`
  color: ${colors.black};
`

export const DetailIcon = css`
  & > div {
    display: inline-block;
    width: 12px;
    height: 10px;
    margin-right: 12px;
  }
`
